<template>
<div>
  <Row :gutter="10">
    <Col span="3">
      <DatePicker clearable type="daterange" split-panels placeholder="发布日期" v-model="dateRange" @on-clear="dateRange = []"></DatePicker>
    </Col>
    <Col span="2">
      <Button type="primary" @click="search()">查询</Button>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24">
      <Table stripe border :columns="columns" :data="list">
        <template slot-scope="{index}" slot="no">
          {{index+1}}
        </template>
        <template slot-scope="{row}" slot="action">
          <Button type="default" @click="hide(row.quan_id, 1)" v-if="row.quan_hidden === 0">隐藏</Button>
          <Button type="default" @click="hide(row.quan_id, 0)" v-else>取消隐藏</Button>
          <Button style="margin-left: 15px" type="error" @click="del(row.quan_id)">删除</Button>
          <Button style="margin-left: 15px" type="primary" @click="detail(row.quan_id)">详细</Button>
        </template>
      </Table>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24" style="text-align: center">
      <Page show-sizer show-elevator show-total :total="total" :current="searchKey.page" :page-size="30" @on-change="pageChange" @on-page-size-change="pageSizeChange"/>
    </Col>
  </Row>
</div>
</template>

<script>
import api from '../../api/xiaochengxu';
import filter from '../../filter';
export default {
  name: "Quan",
  data: function(){
    return {
      dateRange: [],
      searchKey: {
        page: 1,
        pagesize: 30,
        datetime: []
      },
      columns: [
        {title: '#', slot: 'no', width: 80},
        {title: '发布人', key: 'user_nick'},
        {title: '转发自', key: 'from_user_nick'},
        {title: '赞数', key: 'quan_like', width: 80},
        {title: '评论数', key: 'quan_comment', width: 80},
        {title: '发布时间', key: 'quan_datetime', width: 180},
        {title: '操作', slot: 'action', width: 280},
      ],
      total: 0,
      list: [],
    }
  },
  watch: {
    dateRange: {
      handler: function(){
        this.searchKey.datetime = [];
        if(this.dateRange[1]) this.dateRange[1].setDate(this.dateRange[1].getDate())
        for(let d of this.dateRange) {
          if(d !== '') {
            this.searchKey.datetime.push(filter.formatDate2(d))
          }
        }
      },
      immediate: false
    }
  },
  methods: {
    search: function () {
      this.searchKey.page = 1;
      this.getList()
    },
    getList: function(){
      this.$http.get(api.quanList, {params: this.searchKey}).then(response => {
        if(response.data.success) {
          this.list = response.data.res;
          this.total = response.data.count;
        } else {
          this.$Message.error(response.data.err);
          console.log(response);
        }
      })
    },
    pageSizeChange: function(size){
      this.searchKey.pagesize = size;
      this.getList();
    },
    pageChange: function(p){
      this.searchKey.page = p;
      this.getList();
    },
    hide: function(id, ac){
      this.$Modal.confirm({
        content: '<p>确实要隐藏或取消隐藏这一项吗？</p>',
        onOk: () => {
          this.$http.patch(api.hideQuan, {id:id,hide: ac}).then(response => {
            if(response.data.success) {
              this.$Message.success('操作成功');
              this.getList();
            } else {
              this.$Message.error(response.data.err);
              console.log(response);
            }
          })
        },
      });
    },
    del: function(id){
      this.$Modal.confirm({
        content: '<p>确实要删除这一项吗？</p>',
        onOk: () => {
          this.$http.delete(api.delQuan, {data: {id:id}}).then(response => {
            if(response.data.success) {
              this.$Message.success('操作成功');
              this.getList();
            } else {
              this.$Message.error(response.data.err);
              console.log(response);
            }
          })
        },
      });
    },
    detail: function(id){
      this.$router.push({path: '/xiaochengxu/quan/detail', query: {id:id}})

    }
  },
  mounted() {
    this.getList();
  }
}
</script>

<style scoped>

</style>